import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { Fade } from 'react-awesome-reveal'

import ArrowLink from '../Atoms/ArrowLink'

import { labels, localeUrls } from '../../langs/langs'

import gper from '../../images/grad-percentage.svg'
import garr from '../../images/grad-arrow.svg'
import geur from '../../images/grad-euro.svg'
import gcir from '../../images/grad-circle.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const ServicesCard = ({ icon, children, bgImg, localeID, to }) => {
  return (
    <AnchorLink
      className="p-16 sm:p-24 text-center text-white font-medium relative overflow-hidden group bg-bg-dark/60"
      to={to}
    >
      {bgImg}
      <Fade>
        <img src={icon} className="block relative mx-auto inset-x-0 w-16 h-16" />
      </Fade>
      <div className="py-10">{children}</div>
      <ArrowLink span={true} className="text-green justify-center">
        {labels[localeID]['MORE']}
      </ArrowLink>
    </AnchorLink>
  )
}

const serviceCardImgClasses = '!absolute inset-overflow -z-10 duration-500 group-hover:scale-105'
const sCardBg1 = (
  <StaticImage
    src="../../images/serv1.jpg"
    className={serviceCardImgClasses}
    alt="backgound"
    objectPosition="center top"
  />
)
const sCardBg2 = (
  <StaticImage
    src="../../images/serv2.jpg"
    className={serviceCardImgClasses}
    alt="backgound"
    objectPosition="center top"
  />
)
const sCardBg3 = (
  <StaticImage
    src="../../images/serv3.jpg"
    className={serviceCardImgClasses}
    alt="backgound"
    objectPosition="center top"
  />
)
const sCardBg4 = (
  <StaticImage
    src="../../images/serv4.jpg"
    className={serviceCardImgClasses}
    alt="backgound"
    objectPosition="center top"
  />
)

function ServicesPanel({ localeID }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
      <ServicesCard
        localeID={localeID}
        icon={gper}
        bgImg={sCardBg1}
        to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_TC']}`}
      >
        {parse(labels[localeID]['SER_1'])}
      </ServicesCard>
      <ServicesCard
        localeID={localeID}
        icon={garr}
        bgImg={sCardBg2}
        to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_CA']}`}
      >
        {parse(labels[localeID]['SER_2'])}
      </ServicesCard>
      <ServicesCard
        localeID={localeID}
        icon={geur}
        bgImg={sCardBg3}
        to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_PA']}`}
      >
        {parse(labels[localeID]['SER_3'])}
      </ServicesCard>
      <ServicesCard
        localeID={localeID}
        icon={gcir}
        bgImg={sCardBg4}
        to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_AS']}`}
      >
        {parse(labels[localeID]['SER_4'])}
      </ServicesCard>
    </div>
  )
}

export default ServicesPanel
