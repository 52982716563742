import React, { useRef, useEffect, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl'

import { loadExternalScript } from '../../functions/hooks'

import { Content, ArrowLink } from '../Atoms'

import { labels } from '../../langs/langs'

import geoI from '../../images/geo-marker-big.svg'
import markerI from '../../images/location-map.svg'

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN

const MyMap = ({ localeID }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)

  const lat = 48.1686011
  const lng = 17.1503262
  const zoom = 16

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    })
    map.current.scrollZoom.disable()
    map.current.addControl(new mapboxgl.NavigationControl())
    map.current.setStyle(process.env.GATSBY_MAPBOX_STYLE)
    const marker = document.createElement('img')
    //marker.className = 'bg-white h-[50px] w-[50px]'
    marker.src = markerI
    new mapboxgl.Marker({
      element: marker,
      anchor: 'bottom'
    })
      .setLngLat([17.1503262, 48.1686011])
      .addTo(map.current)
  }, [])

  return (
    <div className="min-h-[24rem] h-[65vh] relative">
      <Content className="h-full !absolute">
        <div className="absolute bottom-0 z-10 bg-white px-14 py-10 hidden sm:block">
          <img src={geoI} />
          <p className="py-5 font-medium">
            <strong>Tomášikova 50/E</strong>
            <br /> 831 04 Bratislava
            <br /> Slovenská republika
          </p>
          <ArrowLink
            to="https://www.google.sk/maps/dir//Tom%C3%A1%C5%A1ikova+12573%2F50E,+831+04+Bratislava/@48.1686047,17.1481375,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x476c8ec4c2a5eae3:0x1301b3cc11e0697c!2m2!1d17.1503262!2d48.1686011?hl=en"
            className="text-blue"
            target="_blank"
          >
            {labels[localeID]['NAVIGATE']}
          </ArrowLink>
        </div>
        <ArrowLink
          to="https://www.google.sk/maps/dir//Tom%C3%A1%C5%A1ikova+12573%2F50E,+831+04+Bratislava/@48.1686047,17.1481375,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x476c8ec4c2a5eae3:0x1301b3cc11e0697c!2m2!1d17.1503262!2d48.1686011?hl=en"
          className="button2 sm:hidden absolute z-10 bottom-3"
          target="_blank"
        >
          {labels[localeID]['NAVIGATE']}
        </ArrowLink>
      </Content>
      <div ref={mapContainer} className="h-full"></div>
    </div>
  )
}

export default MyMap
