import React from 'react'
import parse from 'html-react-parser'
import { Fade } from 'react-awesome-reveal'

import { Content } from '../Atoms'

import { labels } from '../../langs/langs'

import person from '../../images/circle-person.svg'
import shield from '../../images/shield-check.svg'
import spider from '../../images/spider.svg'

const ValueRow = ({ children, icon }) => {
  return (
    <div className="hcenter gap-6">
      <Fade>
        <img src={icon} />
      </Fade>
      <p>{children}</p>
    </div>
  )
}

function ValueStack({ localeID }) {
  return (
    <div className="bg-light py-12">
      <Content className="grid grid-cols-1 gap-10 lg:grid-cols-3">
        <ValueRow icon={person}>{parse(labels[localeID]['VALUE_P_1'])}</ValueRow>
        <ValueRow icon={shield}>{parse(labels[localeID]['VALUE_P_2'])}</ValueRow>
        <ValueRow icon={spider}>{parse(labels[localeID]['VALUE_P_3'])}</ValueRow>
      </Content>
    </div>
  )
}

export default ValueStack
