import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import { lSVG } from '../Atoms'

import { labels, localeUrls } from '../../langs/langs'

function FullPageHero({ localeID, blue = false, title, children }) {
  return (
    <div
      className={`lg:h-[calc(100vh_-_3.75rem)] min-h-max py-16 px-5 hcenter justify-center ${classNames(
        {
          'bg-blue text-white': blue,
          'bg-light': !blue
        }
      )}`}
    >
      <div className="text-center">
        <h1 className="text-6xl text-center font-bold -tracking-[1px]">{title}</h1>
        <p className="py-10">{children}</p>
        <a
          onClick={() => navigate(-1)}
          className={`text-blue hcenter justify-center cursor-pointer group ${classNames({
            'text-green': blue
          })}`}
        >
          {lSVG()} <span>{labels[localeID]['BACK']}</span>
        </a>
      </div>
    </div>
  )
}

export default FullPageHero
