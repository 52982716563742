import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { subscriptionInit, validateSubscriptionForm } from '../../functions/reducers'
import { useForm } from '../../functions/hooks'

import { Content } from '../Atoms'
import { CheckBox } from './ContactForm'

import { labels, localeUrls } from '../../langs/langs'

const NewsSubscription = ({ localeID }) => {
  const { errors, handleInputChange, Submit } = useForm(
    50,
    subscriptionInit,
    validateSubscriptionForm,
    localeID,
    localeUrls[localeID]['THX_SUB']
  )
  useEffect(() => {
    handleInputChange({ target: { name: 'yourLang', value: localeID.slice(0, 2) } })
  }, [localeID])
  return (
    <div className="py-24 sm:py-32 bg-light print:hidden">
      <Content>
        <h2 className="font-bold text-3xl">{labels[localeID]['NEWS_SUBSC']}</h2>
        <p className="font-medium pt-2 pb-8">
          {labels[localeID]['FOOTER_TIPS'].replace(/<\/?[^>]+(>|$)/g, '')}
        </p>
        <div className="hcenter gap-0">
          <input
            type="text"
            placeholder="Email"
            className={`bg-gray h-12 w-96 max-w-full min-w-0 font-medium text-blue outline-none pl-4 pr-12 rounded-none border border-transparent ${classNames(
              { '!border-red': errors.yourMail }
            )}`}
            name="yourMail"
            onChange={handleInputChange}
          />
          <Submit className="button3 -ml-12" title={labels[localeID]['SUBSC']} />
        </div>
        <CheckBox
          onChange={handleInputChange}
          name="privacy"
          hasError={errors.privacy}
          className="mt-4"
          light={true}
          bgColor="bg-gray"
        >
          {labels[localeID]['CF_AGREE1']}{' '}
          <Link className="underline" to={localeUrls[localeID]['GDPR']}>
            {labels[localeID]['CF_AGREE2']}
          </Link>
          .
        </CheckBox>
      </Content>
    </div>
  )
}

export default NewsSubscription
