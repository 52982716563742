import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

import { ArrowLink, Content } from '../Atoms'

import { labels, localeUrls } from '../../langs/langs'

import face from '../../images/face-blue.svg'
import divi from '../../images/division-blue.svg'
import phone from '../../images/phone-blue.svg'
import pig from '../../images/pig-blue.svg'

const FourStepsStep = ({ icon, children, number }) => {
  return (
    <div>
      <div className="hcenter">
        <img src={icon} className="w-10 h-10" />
        <div className="flex-auto" />
        <span className="text-6xl -tracking-[1px] text-light font-bold">{number}</span>
      </div>
      <div className="text-sm lg:text-base">{children}</div>
    </div>
  )
}

const FourSteps = ({ localeID }) => {
  return (
    <div className="py-24 sm:py-32">
      <Content>
        <h2 className="text-center font-bold text-5xl -tracking-[0.5px]">
          {labels[localeID]['FOUR_STEPS']}, <br />{' '}
          <span className="text-2xl -tracking-[0.1px]">
            {parse(labels[localeID]['FOUR_STEPS_C'])}
          </span>
        </h2>
        <div className="grid grid-cols-1 gap-8 py-16 sm:grid-cols-2 sm:gap-12 lg:py-20 lg:grid-cols-4 xl:gap-24 2xl:gap-32">
          <Fade cascade={true} damping={0.15} triggerOnce={false}>
            <FourStepsStep icon={phone} number="01.">
              {parse(labels[localeID]['FOUR_STEP_1'])}
              <ArrowLink to={localeUrls[localeID]['CTA']} className="text-blue pt-4">
                {labels[localeID]['WORK_W_US']}
              </ArrowLink>
            </FourStepsStep>
            <FourStepsStep icon={face} number="02.">
              {parse(labels[localeID]['FOUR_STEP_2'])}
            </FourStepsStep>
            <FourStepsStep icon={divi} number="03.">
              {parse(labels[localeID]['FOUR_STEP_3'])}
            </FourStepsStep>
            <FourStepsStep icon={pig} number="04.">
              {parse(labels[localeID]['FOUR_STEP_4'])}
            </FourStepsStep>
          </Fade>
        </div>
        <p className="text-center">
          <Fade>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </Fade>
        </p>
      </Content>
    </div>
  )
}

export default FourSteps
