import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

import { Content } from '../Atoms'

import { labels, localeUrls } from '../../langs/langs'

import softB from '../../images/software-blue.svg'
import chartB from '../../images/chart.svg'
import docuB from '../../images/docu.svg'
import textB from '../../images/textcloud-blue.svg'

const BonusRow = ({ icon, children, bgImg, imgLeft = true, title }) => {
  const illustration = (
    <div className="absolute top-0 opacity-20 h-full sm:static sm:opacity-100">{bgImg}</div>
  )
  return (
    <div className="relative grid grid-cols-1 my-10 sm:grid-cols-2 sm:gap-8 sm:my-24  lg:gap-36 2xl:gap-48">
      {imgLeft && illustration}
      <div className="hcenter py-14">
        <div className="flex items-start gap-4 z-10">
          <img src={icon} />

          <div>
            <h3 className="text-lg font-medium pb-2 2xl:text-2xl">{parse(title)}</h3>
            {children}
          </div>
        </div>
      </div>
      {!imgLeft && illustration}
    </div>
  )
}

const Bonuses = ({ localeID }) => {
  return (
    <div className="py-24 sm:py-32" id={localeUrls[localeID]['BONUSES']}>
      <Content>
        <h2 className="text-center font-bold text-5xl -tracking-[0.5px]">
          {labels[localeID]['BONUSES']}, <br />{' '}
          <span className="text-2xl -tracking-[0.1px]">{labels[localeID]['BONUSES_C']}</span>
        </h2>
        <BonusRow
          title={labels[localeID]['BONUS_1_T']}
          icon={softB}
          bgImg={
            <StaticImage
              src="../../images/bonus1.png"
              className="max-h-full h-full"
              objectFit="contain"
            />
          }
        >
          {labels[localeID]['BONUS_1']}
        </BonusRow>
        <BonusRow
          title={labels[localeID]['BONUS_2_T']}
          icon={chartB}
          bgImg={
            <StaticImage
              src="../../images/bonus2.png"
              className="max-h-full h-full"
              objectFit="contain"
            />
          }
          imgLeft={false}
        >
          {labels[localeID]['BONUS_2']}
        </BonusRow>
        <BonusRow
          title={labels[localeID]['BONUS_3_T']}
          icon={docuB}
          bgImg={
            <StaticImage
              src="../../images/bonus3.png"
              className="max-h-full h-full"
              objectFit="contain"
            />
          }
        >
          {labels[localeID]['BONUS_3']}
        </BonusRow>
        <BonusRow
          title={labels[localeID]['BONUS_4_T']}
          icon={textB}
          bgImg={
            <StaticImage
              src="../../images/bonus4.png"
              className="max-h-full h-full"
              objectFit="contain"
            />
          }
          imgLeft={false}
        >
          {labels[localeID]['BONUS_4']}
        </BonusRow>
        <p className="text-center">
          <Fade>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </Fade>
        </p>
      </Content>
    </div>
  )
}

export default Bonuses
