import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

import { Content, PersonContainer } from '../Atoms'

import { labels, localeUrls } from '../../langs/langs'

const MakeMore = ({ localeID }) => {
  return (
    <div className="py-24 sm:py-32 bg-blue relative print:hidden">
      <PersonContainer className="h-[120%] top-auto" />
      <Content>
        <h2 className="text-white font-bold text-4xl sm:text-5xl -tracking-[0.5px] pb-12 max-w-lg py">
          {parse(labels[localeID]['MAKE_EFF'])}
        </h2>
        <Fade>
          <Link to={localeUrls[localeID]['CTA']} className="button2">
            {labels[localeID]['WORK_W_US']}
          </Link>
        </Fade>
      </Content>
    </div>
  )
}

export default MakeMore
